import loadable from '@loadable/component'

const routes = [
  {
    path: '/',
    exact: true,
    component: loadable(() => import('@/view/index/index')),
  },
  {
      path: '/about-us',
      exact: true,
      component: loadable(() => import('@/view/about/about')),
  },
  {
    path: '/course-center',
    exact: true,
    component: loadable(() => import('@/view/course-center/course-center')),
  },
  {
    path: '/course-detail',
    exact: true,
    component: loadable(() => import('@/view/course-detail/course-detail')),
  },
]

export default routes
